import { Languages } from "constants/languages";
import { mergeDeepLeft } from "ramda";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { LanguageAction, LanguageState } from "./type";

export const useLanguage = create<LanguageState & LanguageAction>()(
  persist(
    immer((set) => ({
      language: Languages[0].code,
      setLanguage: (val: string) =>
        set((state) => {
          state.language = val;
        }),
    })),
    {
      name: "language",
      storage: createJSONStorage(() => sessionStorage),
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState as object, currentState),
    },
  ),
);
