import { mergeDeepLeft } from "ramda";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { AuthAction, AuthState } from "./style";

export const useAppToken = create<AuthState & AuthAction>()(
  persist(
    immer((set) => ({
      token: "",
      setToken: (val: string) =>
        set((state) => {
          state.token = val;
        }),
    })),
    {
      name: "token",
      storage: createJSONStorage(() => sessionStorage),
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState as object, currentState),
    },
  ),
);
