/** @jsxImportSource @emotion/react */

import { authApi } from "apis/auth";
import { Languages } from "constants/languages";
import { PATH } from "constants/path";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppToken } from "stores/auth-state";
import { useLanguage } from "stores/language-state";

import { LoadingPageStyle } from "./style";

const LoadingPage = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  //
  const setToken = useAppToken((state) => state.setToken);
  const setLanguage = useLanguage((state) => state.setLanguage);

  useEffect(() => {
    checkToken();
  }, [params]);

  const checkToken = async () => {
    try {
      const token = params.get("token");
      if (!token)
        return navigate(`${PATH.LoginPage}`, {
          replace: true,
        });
      await authApi.verifyToken(token as string);
      setToken(token as string);
      const language = params.get("language");
      if (!language) return setLanguage(Languages[0].code);
      setLanguage(language as string);
    } catch (error: any) {
      navigate(`${PATH.error}?code=${error?.response?.status || 401}`, {
        replace: true,
      });
    }
  };

  return (
    <div css={LoadingPageStyle.index}>
      <img src="/logo.png" alt="" />
      <i className="fa-solid fa-spinner"></i>
    </div>
  );
};

export default LoadingPage;
