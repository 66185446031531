// sử dụng translate với t("alert[key]")
export const ErrorMessages = [
  "afterBettingTime",
  "beforeBettingTime",
  "notEnoughBalance",
  "amountMustPositive",
  "oddsMustPositive",
  "meronMinBet",
  "drawMinBet",
  "walaMinBet",
  "meronMaxBet",
  "drawMaxBet",
  "walaMaxBet",
  "meronMaxRound",
  "drawMaxRound",
  "walaMaxRound",
  "fightId",
];
