import App from "App";
import { PATH } from "constants/path";
import DefaultLayout from "layouts/DefaultLayout";
import DetectLayout from "layouts/DetectLayout";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const DHomePage = lazy(() => import("pages/DesktopView/HomePage"));
const MHomePage = lazy(() => import("pages/MobileView/HomePage"));
const MatchHistory = lazy(() => import("pages/DesktopView/MatchHistory"));
const Schedule = lazy(() => import("pages/DesktopView/Schedule"));
const Guide = lazy(() => import("pages/DesktopView/Guide"));
const TermsConditions = lazy(() => import("pages/DesktopView/TermsConditions"));
const MMatchHistory = lazy(() => import("pages/MobileView/MatchHistory"));
const MGuidePage = lazy(() => import("pages/MobileView/MGuidePage"));
const MTermsConditions = lazy(() => import("pages/MobileView/TermsConditions"));
const MSchedule = lazy(() => import("pages/MobileView/MSchedule"));
//
const ErrorPage = lazy(() => import("pages/Common/ErrorPage"));
const LoginPage = lazy(() => import("pages/Common/LoginPage"));

export const routesConfig: RouteObject[] = [
  {
    path: PATH.error,
    element: <ErrorPage />,
  },
  {
    path: PATH.LoginPage,
    element: <LoginPage />,
  },
  {
    path: PATH.Root,
    element: <App />,
    children: [
      {
        element: <DefaultLayout />,
        children: [],
      },
      {
        element: <DetectLayout />,
        children: [
          {
            path: PATH.HomePage,
            element: <DHomePage />,
          },
          {
            path: PATH.MatchHistory,
            element: <MatchHistory />,
          },
          {
            path: PATH.Schedule,
            element: <Schedule />,
          },
          {
            path: PATH.Guide,
            element: <Guide />,
          },
          {
            path: PATH.TermsConditions,
            element: <TermsConditions />,
          },
          {
            element: <MHomePage />,
            path: PATH.MHomePage,
          },
          {
            element: <MMatchHistory />,
            path: PATH.MMatchHistory,
          },
          {
            element: <MGuidePage />,
            path: PATH.MGuidePage,
          },
          {
            element: <MTermsConditions />,
            path: PATH.MTermsConditions,
          },
          {
            element: <MSchedule />,
            path: PATH.MSchedule,
          },
        ],
      },
    ],
  },
];
