import axios, { AxiosInstance } from "axios";

export const getToken = () => {
  const token = sessionStorage.getItem("token");
  if (token) {
    return `Bearer ${JSON.parse(token).state.token}`;
  }
  return null;
};

const axiosRequest: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  headers: {
    "Content-Type": "application/json",
  },
});
axiosRequest.defaults.timeout = 1000 * 30;
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      // Đường dẫn đến trang đăng nhập của bạn
      window.location.replace("/login");
    }
    return Promise.reject(error);
  },
);
export default axiosRequest;
