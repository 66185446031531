/** @jsxImportSource @emotion/react */

import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { DefaultLayoutStyle } from "./style";

function DefaultLayout() {
  return (
    <div css={DefaultLayoutStyle.self}>
      <main css={DefaultLayoutStyle.index}>
        <Suspense fallback={<>Loading...</>}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
}

export default DefaultLayout;
