import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "stores/language-state";

export const useIP = () => {
  const { i18n } = useTranslation();
  const language = useLanguage((state) => state.language);
  useEffect(() => {
    function checkLanguage() {
      if (!language) return;
      i18n.changeLanguage(language);
    }
    checkLanguage();
  }, [language, i18n]);
};
