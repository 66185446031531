import { css } from "@emotion/react";

import { theme } from "./theme";

export const reset = css`
  /* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"); */
  * {
    font-family: "Inter", sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  *:not(.non-reset *) {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  body {
    margin: 0;
    padding: 0px;
    background: ${theme.colors.background};
    color: ${theme.colors.textColor};
  }
  a {
    text-decoration: none;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;
