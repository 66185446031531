import "./i18n";

import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useIP } from "hooks/useIP";
import LoadingPage from "pages/Common/LoadingPage";
import { Outlet } from "react-router-dom";
import { useAppToken } from "stores/auth-state";
import GlobalStyles from "styles/global";
import { theme } from "styles/theme";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    },
  },
});

function App() {
  const token = useAppToken((state) => state.token);
  useIP();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        {token ? <Outlet /> : <LoadingPage />}
        <ReactQueryDevtools
          buttonPosition="bottom-left"
          initialIsOpen={false}
          position="left"
          client={queryClient}
        />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
