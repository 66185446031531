import { css, keyframes } from "@emotion/react";

const spin = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
`;
export const LoadingPageStyle = {
  index: css`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 100px;
      margin-bottom: 0;
    }
    i {
      font-size: 30px;
      animation: ${spin} 1s linear infinite;
    }
  `,
};
