import axiosRequest from "apis";
import { ErrorMessages } from "constants/errors-message";
import { t } from "i18next";

import { LoginFormProps } from "./type";

export const authApi = {
  verifyToken: async (token: string) => {
    try {
      const res = await axiosRequest.get(`/api/verify-token?token=${token}`);
      return res.data;
    } catch (error: any) {
      const aiosError = error as any;
      if (
        aiosError &&
        aiosError?.response &&
        aiosError?.response?.data.message
      ) {
        const message = ErrorMessages.find((i) =>
          `${aiosError.response.data.message}`.includes(i),
        );
        return Promise.reject(t(`errors.${message}`));
      }
      return Promise.reject(error.message);
    }
  },
  login: async (payload: LoginFormProps) => {
    try {
      const res = await axiosRequest.post(`/api/authenticate`, payload);
      return res.data;
    } catch (error: any) {
      const aiosError = error as any;
      if (
        aiosError &&
        aiosError?.response &&
        aiosError?.response?.data.message
      ) {
        const message = ErrorMessages.find((i) =>
          `${aiosError.response.data.message}`.includes(i),
        );
        return Promise.reject(t(`errors.${message}`));
      }
      return Promise.reject(error.message);
    }
  },
};
