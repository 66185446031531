/** @jsxImportSource @emotion/react */

import useWindowSize from "hooks/useWindowSize";
import { DefaultLayoutStyle } from "layouts/DefaultLayout/style";
import { Suspense, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function DetectLayout() {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (width > 768) {
      if (pathname.startsWith("/m/") || pathname === "/m")
        return navigate(pathname.replace("/m", ""), { replace: true });
    } else {
      if (pathname.startsWith("/m/") || pathname === "/m")
        return navigate(pathname, { replace: true });
      navigate("/m" + pathname, { replace: true });
    }
  }, [width, pathname]);
  return (
    <div css={DefaultLayoutStyle.self}>
      <main css={DefaultLayoutStyle.index}>
        <Suspense fallback={<>Loading...</>}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
}

export default DetectLayout;
